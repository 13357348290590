<template>
    <div>
        <div class="body-l" v-if="showList">
            <div class="mask" @click="clickMask"></div>
            <div
                class="body-l-item"
                :style="{background:selectName!=item.name?'#202020':'#fff'}"
                v-for="item in classList"
                :key="item.name"
                @click="getClass(item)"
            >
                <img :src="selectName!=item.name?item.url:item.surl" alt="">

                <span :style="{color:selectName!=item.name?'#c2c2c2':'#202020'}">{{ item.name }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus.js'
import navMenuList from "@/utils/navMenu"
import { getClassList } from "@/api/games"
import { isMobile } from "@/utils/api";

export default {
    data() {
        return {
            showList: true,
            selectName: 'All',
            classList: [
                {
                    name: 'All',
                    url: require('@/assets/class/ic_all.png'),
                    surl: require('@/assets/class/ic_all1.png')
                },
            ]
        }
    },
    mounted() {
        eventBus.$on('showlist', item => {
            this.showList = item;
        })
        this.getClassList();
    },
    methods: {
        clickMask() {
            eventBus.$emit('clickMask', false);
            this.showList = false;
        },
        getClass(key) {
            this.selectName = key.name;
            if(key.name == "All"){
                if(this.$route.path!='/'){
                    this.$router.push('/')
                }else{
                    this.$router.go(0)
                }
                return
            }
            if(isMobile()){
                eventBus.$emit('clickMask', false);
                this.showList = false;
            }
            if (this.$route.path !== '/selectGame') {
                this.$router.push({
                    path: '/selectGame',
                    query: {
                        id: 'more',
                        key: key.name
                    }
                })
            } else {
                eventBus.$emit('searchgame', {
                    id: 'more',
                    key: key.name
                });
            }
        },
        async getClassList() {
            let classlist = await getClassList();
            classlist.forEach((item) => {
                navMenuList.forEach(items => {
                    if (item.key_name == items.name) {
                        this.classList.push(items)
                    }
                })
            })
        }
    },
}
</script>

<style lang="less" scoped>
@media (max-width: 768px) {
    .body-l {
        .mask {
            position: fixed;
            top: 60px;
            left: 186px;
            width: calc(100vw - 186px);
            height: calc(100vh - 60px);
            background-color: rgba(51, 51, 51, 0.9);
        }

        background-color: #323232;
        height: calc(100vh - 60px);
        position: fixed;
        top: 60px;
        left: 0;
        overflow: auto;
    }
}

@media (min-width: 768px) {
    .body-l {
        &::-webkit-scrollbar{
            width: 7px;
        }
        &::-webkit-scrollbar-thumb{
            border-radius: 10px;
            box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            background: rgba(192,192,192,0.2);
        }
        &::-webkit-scrollbar-track{
            box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            border-radius: 0;
            background: rgba(192,192,192,0.1);
        }
        background-color: #202020;
        height: calc(100vh - 120px);
        position: fixed;
        top: 60px;
        left: 0;
        overflow: auto;
    }
}

.body-l {
    position: fixed;
    top: 60px;
    left: 0;
    width: 186px;
    height: calc(100vh - 60px);
    overflow: auto;
    border-right: 1px solid #323232;

    &::before {
        content: "";
        display: table;
    }

    .body-l-item {
        width: 130px;
        height: 45px;
        display: flex;
        align-items: center;
        padding-left: 5px;
        border-radius: 10px;
        box-sizing: border-box;
        margin: 10px auto 0;
        cursor: pointer;

        &:hover span {
            transform: translateX(5px);
            transition: transform 0.2s linear;
        }

        span {
            margin-left: 12px;
            width: 24px;
            height: 28px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            line-height: 28px;
            text-align: left;
            font-style: normal;
        }

        img {
            width: 27px;
            height: 27px;
        }
    }
}
</style>