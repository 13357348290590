<template>
    <div>
        <div class="body-r">
            <div class="mobile-search" v-if="showSearch">
                <div>
                    <img src="@/assets/slices/ic_sousuo.png" alt="">
                    <input v-model="searchText" placeholder="keywords" @keyup.enter="searchGame()"
                    type="text">
                    <div v-if="showSearchList">
                        <div v-for="item in gameList[0].games" :key="item.id"
                        @click="goDetails(item)">
                            {{item.title}}
                        </div>
                    </div>
                </div>
            </div>
            <adContent></adContent>
            <div class="body-r-class" v-for="item in gameList" :key="item.title">
                <p class="class-name">
                    <img  :src="item.url" alt="">
                    <span>{{item.title}}</span>
                </p>
                <div
                class="game-card"
                v-for="items in item.games"
                :key="items.id"
                @click="goDetails(items)">
                    <div >
                        <img :src="items.logo" alt="">
                    </div>
                    <span>{{items.title}}</span>
                </div>
            </div>
            <div class="game-blurb noselect" style="color: #FFF">
                {{ blurbUrl }} is a renowned online gaming destination that combines a rich selection of casual games with a vibrant community of players. Owned by Electronic Arts, Pogo offers a curated library of over 100 titles, including popular favorites like Scrabble, Word Whomp, and Poppit! The platform's user-friendly interface and intuitive controls cater to a wide audience, making it accessible to both casual gamers and seasoned veterans. Pogo's Club Pogo membership provides access to exclusive games, ad-free play, and special features like tournament participation and early access to new content. The site's forums and chat rooms foster a sense of belonging among players, encouraging friendly competition and collaborative gameplay.
            </div>
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus.js'
import { isMobile } from "@/utils/api"
import { getGameList, getGameByKey } from "@/api/games"
import iconList from '@/utils/navMenu'
import adContent from "@/components/adContent/index.vue";
    export default {
        components: {
            adContent

        },
        data() {
            return {
                gameList:[],
                gameListC:[],
                allList:[],
                showSearch: false,
                searchText:'',
                showSearchList: false,
                blurbUrl: process.env.VUE_APP_BASE_GAME
            }
        },
        mounted() {
            let searchText = this.$route.params.key;
            if(searchText){
                this.searchGame(searchText)
            }else{
                this.getList();
            }
            eventBus.$on('gameList',key=>{
                this.getClass(key)
            });
            eventBus.$on('searchKey',key=>{
                this.searchGame(key);
            })
            if(isMobile()){
                this.showSearch = true;
                eventBus.$emit('showlist',false);
            }else{
                eventBus.$emit('showlist',true)
            }
            let key = this.$route.params.key;
            if(key){
                this.getClass(key)
            }
        },
        methods: {
            getList(){
                getGameList().then(res=>{
                    Object.keys(res).forEach(key=>{
                        console.log(key);
                        
                        iconList.forEach(keys=>{
                            if(key.indexOf(keys.name) != -1){
                                this.gameList.push({
                                    title: key,
                                    games: res[key],
                                    url: keys.wurl
                                })
                            }
                        })
                    })
                    console.log(this.gameList);
                    
                    this.gameListC = JSON.parse(JSON.stringify(this.gameList));                
                })
            },
            getClass(key){        
                this.selectName = key.name;
                
                if(key.name==='All'){
                    this.gameList = this.gameListC;
                }else{
                    getGameByKey({category:key.name}).then(res=>{
                        this.gameList = [
                            {
                                title: key.name,
                                games: res
                            }
                        ]
                        this.gameList.forEach(item=>{
                            iconList.forEach(keys=>{
                                if(item.title == keys.name){
                                    item.url = keys.wurl
                                }
                            })
                        })
                        console.log(this.gameList);
                                     
                    })
                }
            },
            goDetails(item){
                let id = item.id;

                this.$router.push({
                    path:'/details',
                    query:{
                        id
                    }
                })
            },
            searchGame(){
                this.$router.push({
                    path:'/selectGame',
                    query:{
                        id:'search',
                        key: this.searchText
                    }
                })
            },
        },
    }
</script>

<style lang="less" scoped>
@media (max-width: 768px) {
    .body-r{
        margin-top: 70px;
        .mobile-search{
            // padding: 9px 5px 11px;
            margin: 9px 10px 11px;
            box-sizing: border-box;
            >div{   
                // position: relative;
                width: 100%;
                // height: 40px;
                background: #333333;
                border-radius: 10px;
                display: flex;
                align-items: center;
                background-color: #333;
                height: 35px;
                img{
                    width: 30px;
                    height: 30px;
                }
                input{
                    width: 300px;
                    height: 30px;
                    display: block;
                    font-size: 16px;
                    border: none;
                    outline: none;
                    background-color: #333333;
                    color: #FFF;
                }
                >div{
                    width: calc(100% - 20px);
                    background-color: #333333;
                    top: 100px;
                    position: absolute;
                    >div{
                        margin-left: 5px;
                        height: 30px;
                        line-height: 30px;
                        border-bottom: 1px solid #FFF;
                    }
                }
            }
        }
        .class-after{
            &::after{
                content: '';
                flex: auto;
            }
        }
        .body-r-class{
            // justify-content: space-between;
            padding: 0 10px;
            box-sizing: border-box;
            .game-card{
                width: 115px;
                margin-left: 8px;
                span{
                    font-size: 14px;
                }
                img{
                    height: 115px;
                }
            }
        }
    }
}
@media (min-width: 768px) {
    .body-r{
        padding:30px 0 0 19px;
        box-sizing: border-box;
        margin-left: 186px;
        margin-top: 60px;
        // width: calc(100vw - 186px);
        .game-card{
            width: 169px;
            margin-right: 19px;
            span{
                font-size: 16px;
            }
            img{
                height: 169px;
            }
        }
    }
}
.body-r{
    .body-r-class{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .class-name{
            box-sizing: border-box;
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 19px;
            span{
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 24px;
                color: #FFFFFF;
                line-height: 33px;
                text-align: left;
                font-style: normal;
            }
            img{
                width: 31px;
                height: 31px;
            }
        }
        .game-card{
            margin-bottom: 15px;
            cursor: pointer;
            span{
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 22px;
                text-align: left;
                font-style: normal;
            }
            img{
                border-radius: 7px;
                width: 100%;
                &:hover{
                    transform: scale(1.1);
                    transition: transform 0.3s linear;
                }
            }
        }
    }
}
.game-blurb{
    border-radius: 10px;
    padding: 8px 12px;
    box-sizing: border-box;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #FFF;
    line-height: 22px;
    text-align: left;
    font-style: normal;
}
</style>