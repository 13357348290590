<template>
    <div style="width:100%">
        <div class="content">
            <div class="content-l">
                <!-- <img v-if="isPlay" :src="details.logo" alt=""> -->
                <div class="sahde" v-if="isPlay">
                    <div class="sahde-card">
                        <img :src="details.logo" alt="">
                        <p>{{details.title}}</p>
                        <div class="play-btn" @click="()=>{isPlay = false}">
                            <span>PLAY</span>
                            <div>
                                <img src="@/assets/slices/play.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="gamewin" v-else>
                    <iframe
                    style="width:100%"
                    :src="baseUrl+details.url"
                    frameborder="0"></iframe>
                </div>
                <p>
                    <span style="font-size: 20px;">Description :</span>
                    <br>
                    <span style="font-size: 16px;">{{details.synopsis}}</span>
                </p>
            </div>
            <div class="content-r">
                <p>RECOMMEND FOR YOU :</p>
                <div v-for="item in gameList" :key="item.id" @click="getGame(item)">
                    <img :src="item.logo" alt="">
                    <p style="font-size: 14px;margin-top:12px">{{item.title}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { isMobile } from '@/utils/api'
import eventBus from '@/utils/eventBus'
import { getGameById } from "@/api/games"

    export default {
        data() {
            return {
                baseUrl:'',
                details:{},
                gameList:[],
                isPlay:true
            }
        },
        mounted() {
            this.scrollToTop();
            
            let id = this.$route.query.id;
            getGameById({id}).then(res=>{
                this.details = res.game;
                this.gameList = res.similarGames;
            })

            // this.baseUrl = process.env.VUE_APP_BASE_GAME//'http://www.cindygames.com';
            this.baseUrl = 'https://'+window.location.hostname
            if(isMobile()){
                eventBus.$emit('showlist',false);
            }
        },
        methods: {
            getGame(game){
                this.details = game;
                this.isPlay = true;
            },
            scrollToTop() {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
             }
        },
    }
</script>

<style lang="less" scoped>
@media (max-width: 768px) {
    .content{
        // width: 100vw;
        margin-top: 60px;
        .content-l{
            width: 100%;
            margin-bottom: 20px;
            // >img{
            //     overflow: hidden;
            //     width: 100%;
            //     // height: calc(100vh - 60px);
            //     height: 350px;
            //     float: left;
            //     filter: blur(10px);
            //     border-radius: 8px;
            // }
            .sahde{
                z-index: 1;
                position: relative;
                width: 100%;
                height: 350px;
                // height: calc(100vh - 60px);
                border-radius: 8px;
                background-color: rgba(51, 51, 51, 0.9);
                .sahde-card{
                    width: 150px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    .play-btn{
                        width: 80%;
                        height: 40px;
                        line-height: 60px;
                        margin: 20px auto 0;
                        background: linear-gradient( 180deg, #0AFFD6 0%, #00FFAB 100%);
                        border-radius: 30px;
                        color: #202020;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 20px;
                        div{
                            display: flex;
                            align-items: center;
                            height: 60px;
                            img{
                                width: 20px;
                                height: 20px;
                                margin-left: 10px;
                            }
                        }
                    }
                    p{
                        text-align: center;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 500;
                        font-size: 24px;
                        color: #FFFFFF;
                        line-height: 33px;
                        font-style: normal;
                    }
                    img{
                        width: 150px;
                        height: 150px;
                        border-radius: 7px;
                    }
                }
            }
            .gamewin{
                width: 100%;
                height: calc(100vh - 60px);
                margin-bottom: 30px;
                iframe{
                    width: 100%;
                    height: 100%;
                }
            }
            >p{
                display: -webkit-box;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                // width: 100%;
                margin: 10px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 22px;
                color: #FFFFFF;
                line-height: 20px;
                text-align: left;
                font-style: normal;
                box-sizing: border-box;
            }
        }
        .content-r{
            border-radius: 8px;
            background-color: rgba(51, 51, 51, 0.9);
            display: flex;
            justify-content: space-between;
            padding: 10px;
            flex-wrap: wrap;
            p{
                width: 100%;
                margin-bottom: 15px;
                font-size: 20px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            div{
                width: 102px;
                // height: 45vw;
                margin-bottom: 15px;
                cursor: pointer;
                img{
                    width: 102px;
                    height: 102px;
                    border-radius: 7px;
                    &:hover{
                        transform: scale(1.1);
                        transition: transform 0.3s linear;
                    }
                }
                
            }
        }
    }
}
@media (min-width: 768px){
    .content{
        // width: 100%;
        margin: 60px 0 0 186px;
        padding-top: 25px;
        display: flex;
        justify-content: space-around;
        .content-l{
            width: 1200px;
            height: 734px;
            >img{
                overflow: hidden;
                width: 100%;
                height: 100%;
                float: left;
                filter: blur(10px);
                border-radius: 8px;
            }
            .sahde{
                position: relative;
                width: 100%;
                height: 100%;
                border-radius: 8px;
                background-color: rgba(51, 51, 51, 0.9);
                .sahde-card{
                    width: 300px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    .play-btn{
                        width: 220px;
                        height: 60px;
                        margin: 20px auto 0;
                        background: linear-gradient( 180deg, #0AFFD6 0%, #00FFAB 100%);
                        border-radius: 30px;
                        color: #202020;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 24px;
                        div{
                            display: flex;
                            align-items: center;
                            height: 60px;
                            img{
                                width: 20px;
                                height: 20px;
                                margin-left: 10px;
                            }
                        }
                    }
                    p{
                        text-align: center;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 500;
                        font-size: 24px;
                        color: #FFFFFF;
                        line-height: 33px;
                        font-style: normal;
                    }
                    img{
                        width: 300px;
                        height: 300px;
                        border-radius: 7px;
                    }
                }
            }
            .gamewin{
                width: 100%;
                height: 100%;
                margin-bottom: 30px;
                iframe{
                    width: 100%;
                    height: 100%;
                }
            }
            >p{
                display: -webkit-box;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 22px;
                color: #FFFFFF;
                line-height: 20px;
                text-align: left;
                font-style: normal;
            }
        }
        .content-r{
            width: 380px;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            >p{
                width: 100%;
                margin-bottom: 15px;
                font-size: 20px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
            }
            div{
                width: 102px;
                margin-bottom: 20px;
                cursor: pointer;
                img{
                    width: 102px;
                    height: 102px;
                    border-radius: 7px;
                    &:hover{
                        transform: scale(1.1);
                        transition: transform 0.3s linear;
                    }
                }
                p{
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

</style>