<template>
    <div>
        <div>
            <h2>About Us</h2>
            <div class="wrap content" style="white-space: pre-wrap;">
                    About Us:
                <p>
                    Welcome to Innovative Cooyoo Inc.  Where Creativity Meets Impact
                </p>
                    Core Services:
                <p>
                    In the vibrant landscape of advertising, where every brand strives to stand out and connect deeply with its audience, Innovative Cooyoo Inc emerges as a beacon of innovation and excellence. 
                </p>
                    Our Mission:
                <p>
                    At the heart of our operations lies a simple yet profound mission to elevate brands, inspire audiences, and create a lasting impact through transformative advertising solutions. 
                </p>
                    Core Services:
                <p>
                    Brand Strategy & Positioning: We start by understanding your brand's DNA, conducting thorough market research, and developing a comprehensive brand strategy that sets you apart from the competition.
                </p>
                <p>
                    Creative Services: Our in-house team of award-winning creatives brings your brand vision to life through captivating visuals, engaging content, and compelling narratives across all media channels.
                </p>
                <p>
                    Digital Marketing: Leveraging the latest digital tools and technologies, we craft customized digital strategies that include SEO, PPC, social media marketing, email campaigns, and influencer partnerships to maximize your online presence and reach.
                </p>
                <p>
                    Media Planning & Buying: With extensive industry knowledge and a vast network of media partners, we negotiate the best deals for you, ensuring your message reaches the right audience at the right time.
                </p>
                <p>
                    PR & Event Management: From press releases and media relations to grand openings and product launches, we manage every aspect of your PR and event strategy, helping you build brand awareness and generate buzz.
                </p>
                <p>
                    Data Analytics & Insights: Data drives everything we do. Our analytics team monitors campaign performance in real-time, providing actionable insights that inform future strategies and ensure continuous improvement.
                </p>
                <p>
                    Why Choose Us?
                </p>
                <p>
                    Expertise: With a diverse team of industry veterans and emerging talents, we bring a wealth of knowledge and experience to every project.
                </p>
                <p>
                    Client-Centric Approach: Your success is our priority. We work closely with you to understand your unique needs and tailor our services accordingly.
                </p>
                <p>
                    Results-Driven: Our focus is on delivering measurable results that drive growth and ROI for your business.
                </p>
                <p>
                    At Innovative Cooyoo Inc, we are passionate about helping brands reach new heights. Whether you're a startup looking to make a splash or an established enterprise seeking to reinvent yourself, we have the expertise and creativity to take your advertising efforts to the next level.
                </p>
                <p>
                    Contact Us Today to schedule a consultation and discover how we can transform your brand's advertising journey. Together, we'll make your vision a reality.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus';
    export default {
        mounted() {
            eventBus.$emit('showlist',false)
        },
    }
</script>

<style lang="less" scoped>
h2{
    margin: 30px auto;
    text-align: center;
}
p{
    margin: 10px 0;
    font-size: 16px;
    padding: 0;
}
.content{
    padding: 0 20px;
    box-sizing: border-box;
}
</style>